<template>
  <list-item-selectable
    v-bind="$attrs"
    force-show-actions
    three-line
    v-on="$listeners"
  >
    <template #item:avatar>
      <v-list-item-avatar
        :color="colors[item.currentStateColor] || colors['default']"
      >
        <v-img
          :src="getObjectImageUrlOrDefault(item.currentStateIcon)"
          width="30"
          height="30"
          max-width="30"
          max-height="30"
        />
      </v-list-item-avatar>
    </template>
    <template #item:content>
      <v-list-item-title>
        {{ item.objectName }}
      </v-list-item-title>
      <v-list-item-subtitle class="d-flex align-center ellipsis">
        {{ item.message }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="d-flex align-center">
        <div class="mr-2 text-ellipsis">
          {{ item.description }}
        </div>
        <v-icon :color="item.typeIconColor" small v-text="item.typeIcon" />
      </v-list-item-subtitle>
    </template>
    <template #item:actions>
      <v-list-item-action>
        <v-btn
          icon
          @click.stop.prevent="$emit('click:info')"
          @mousedown.stop=""
        >
          <v-icon v-text="'$info'" />
        </v-btn>
      </v-list-item-action>
    </template>
  </list-item-selectable>
</template>

<script>
import { getObjectImageUrlOrDefault } from '@/provider/utils';
import { ColorNames, colors } from '@/compositions/map/utils/data';

export default {
  name: 'NotificationListItem',
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({
        objectName: '',
        message: '',
        description: '',
        currentStateIcon: '',
        currentStateColor: colors[ColorNames.default],
        typeIcon: '',
        typeIconColor: ''
      })
    }
  },
  setup() {
    return {
      colors,
      getObjectImageUrlOrDefault
    };
  }
};
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  //white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
</style>
